<template>
  <div>
    <div class="tabs">
      <span @click="routerIndex()">技术支持与服务 > </span>
      <span>产品文档</span>
      <!-- <span @click="downloadPDF()">下载</span> -->
    </div>
    <search-box :info="searchInfo" @childEvent="handleChildEvent"></search-box>
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="产品文档" name="first">
          <div class="box">
            <el-card>
              <el-menu default-active="1-0" class="el-menu-vertical-demo">
                <el-menu-item
                  v-for="(item, i) in productList"
                  :key="i"   
                  
                  :index="'1-' + i"
                  @click.native="groupIndex = i"
                >
                  {{ item.type }}
                </el-menu-item>
              </el-menu>
            </el-card>
            <el-card>
              <div
                v-for="(item, i) in productList[groupIndex].list"
                :key="i"
                class="products"
              >
                <div class="name">{{ item.name }}</div>
                <div class="more">
                  <div class="check" @click="checkClick(item)">
                    <img src="../../assets/support/CK.png" alt="" />
                    <span>查看</span>
                  </div>
                  <div class="download" @click="downloadPDF(item)" >
                    <img src="../../assets/support/XZ.png" alt="" />
                    <span>下载</span>
                    <!-- <a
                      :href="item.content"
                      target="_blank"
                      rel="nofollow"
                      title="pdf文档"
                      class="a"
                      download="一体化.pdf"
                      >下载</a
                    >    -->
                  </div> 
                  <!-- <div class="mail">
                    <img src="../../assets/support/YX.png" alt="" />
                    <span>邮箱</span>
                  </div> -->
                </div>
              </div>
              <!-- <div class="pagination">
                <el-pagination             
                  background
                  :pager-count="5"
                  layout="prev, pager, next"
                  :total="1000"
                >
                </el-pagination> 
              </div> -->
            </el-card>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="用户手册" name="second">
          <div class="box" style="height: 7rem">暂未开发</div>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <div class="preview" v-if="isPDF">
      <vue-office-pdf :src="pdfSrc" />
      <img
        class="closeImg"
        @click="closePDF"
        src="../../assets/support/close.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import SearchBox from "./SearchBox.vue";
//引入VueOfficePdf组件
import VueOfficePdf from "@vue-office/pdf";

// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
export default {
  components: { SearchBox, VueOfficePdf },
  data() {
    return {
      message: "",
      searchInfo: {
        title: "产品文档",
        img: require("../../assets/support/tt6@2x.png"),
      },
      activeName: "first",
      groupIndex: 0,
      productList: [
      {
          type: "一体化机柜",
          list: [
            {
              name: "照明一体化控制柜",
              content: "https://oss.zijingshuke.com/1703914683920.pdf",
              doc: "https://oss.zijingshuke.com/1703914797653.docx",
            },
            {
              name: "楼层一体化控制柜",
              content: "https://oss.zijingshuke.com/1703914805788.pdf",
              doc: "https://oss.zijingshuke.com/1703914823384.docx",
            },
            {
              name: "新风机一体化控制柜",
              content: "https://oss.zijingshuke.com/1703914859453.pdf",
              doc: "https://oss.zijingshuke.com/1703914872109.docx",
            },
            {
              name: "空调一体化控制柜",
              content: "https://oss.zijingshuke.com/1703914839699.pdf",
              doc: "https://oss.zijingshuke.com/1703914924263.docx",
            },
            {
              name: "冷水机一体化控制柜",
              content: "https://oss.zijingshuke.com/1703914884402.pdf",
              doc: "https://oss.zijingshuke.com/1703914909153.docx",
            },
          ],
        },
        {
          type: "一体化前端控制模块",
          list: [
            {
              name: "一体化前端控制模块(BXDAS-D)",
              content: "https://oss.zijingshuke.com/1703914981411.pdf",
              doc: "https://oss.zijingshuke.com/1703915028730.docx",
            },
            {
              name: "一体化前端控制模块(BXDAS-M)",
              content: "https://oss.zijingshuke.com/1703914981411.pdf",
              doc: "https://oss.zijingshuke.com/1703915028730.docx",
            },
            {
              name: "一体化前端控制模块(BXDAS-ZM)",
              content: "https://oss.zijingshuke.com/1703914981411.pdf",
              doc: "https://oss.zijingshuke.com/1703915028730.docx",
            },
          ],
        },
        {
          type: "动力柜",
          list: [
            {
              name: "动力柜GDH",
              // content: "https://product.11467.com/ziliao/4768953.pdf",
              content: "https://oss.zijingshuke.com/1703225362598.pdf",
              doc: "https://oss.zijingshuke.com/1703558818203.doc",
            },
          ],
        },
        {
          type: "非标箱",
          list: [
            {
              name: "非标箱BX-4",
              // content: "https://product.11467.com/ziliao/4768951.pdf",
              content: "https://oss.zijingshuke.com/1703902971556.pdf",
              doc: "https://oss.zijingshuke.com/1703558774970.doc",
            },
          ],
        },
        {
          type: "照明箱",
          list: [
            {
              name: "照明箱BX-3",
              // content: "https://product.11467.com/ziliao/4768949.pdf",
              content: "https://oss.zijingshuke.com/1703902998354.pdf",
              doc: "https://oss.zijingshuke.com/1703558815749.doc",
            },
          ],
        },
      ],
      // pdf预览地址
      pdfSrc: "",
      // 是否展示pdf文档
      isPDF: false,
    };
  },
  methods: {
    // 返回技术支持与服务
    routerIndex() {
      this.$router.push("/support");
      this.$store.commit("changeIndex", 2);
    },
    handleClick() {},
    handleChildEvent(value) {
      this.message = value;
      console.log(this.message);
    },
    // 查看文档
    checkClick(item) {
      console.log(item.content);
      this.pdfSrc = item.content;
      this.isPDF = true;
    },
    // 关闭pdf展示
    closePDF() {
      this.isPDF = false;
    },
    downloadPDF(item) {
      const pdfUrl = item.content;
      fetch(pdfUrl)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = decodeURIComponent(item.name.split('/').pop());
          link.target = '_blank';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => {
          console.error(error);
        });
    
      
    },
    downLoad(){
      const pdfUrl = item.content; // 替换为实际的 PDF 文件 URL
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = decodeURIComponent(pdfUrl.split('/').pop());
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
};
</script>

<style scoped lang="scss">
/* // 导航条 */
.tabs {
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  line-height: 48px;
  font-size: 0.2rem;
  padding-top: 8px;
  // margin-top: 1.05rem;
  span:nth-child(1) {
    margin-left: 24px;
    color: #a3a3a3;
    cursor: pointer;
  }
  span:nth-child(2) {
    color: #565656;
    cursor: pointer;
  }
}
// .main{
//   position: relative;
// }
// 预览
.preview {
  position: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  top: 0;
  left: 0;
  // 关闭按钮
  .closeImg {
    position: fixed;
    width: 2em;
    top: 5px;
    right: 5px;
    background: #000;
    border-radius: 5px;
  }
}
// a标签
.a {
  vertical-align: middle;
  color: white;
  text-decoration: none;
}
.content /deep/ .el-tabs__nav {
  left: 1.6rem;
}
.content /deep/ #tab-first,
.content /deep/ #tab-second {
  font-size: 0.24rem;
  font-weight: 600;
}

.el-menu-item {
  color: #909090;
}
.is-active {
  color: #409eff;
}

.el-menu {
  border: 0px;
}
.box {
  display: flex;
  margin: 0.5rem 1.6rem;
}
.el-card {
  width: 4.68rem;
  height: 5rem;
  margin-right: 0.18rem;
}
.el-card:nth-child(2) {
  width: 11.14rem;
  height: 8rem;
  position: relative;
}

.products {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0;
  border-bottom: 2px solid #ebebeb;
}
.products:nth-of-type(1) {
  padding-top: 0.15rem;
}
.products .name {
  color: #272727;
  font-size: 0.2rem;
  cursor: pointer;
}
.more {
  display: flex;
}
.more > div {
  background-color: #127bf2;
  width: 0.88rem;
  height: 0.36rem;
  margin-left: 0.24rem;
  color: white;
  font-size: 0.16rem;
  text-align: center;
  line-height: 0.36rem;
  cursor: pointer;
}
.more img {
  width: 0.17rem;
  height: 0.17rem;
  margin-right: 0.05rem;
  vertical-align: middle;
}
.pagination {
  position: absolute;
  bottom: 0.4rem;
  left: 50%;
  transform: translate(-50%);
}
</style>
